import axios from 'axios'
import { SYNC_SERVER_URL, SyncEndpointType } from './constants'

export async function performSync(
    clinicId: string | null | undefined,
    endpoint: SyncEndpointType
) {
    if (!clinicId) return

    const res = await axios.post(
        `${SYNC_SERVER_URL}/connect/${clinicId}/${endpoint}`
    )

    return res.data
}

export const performCloudFullSync = async (clinicId: string) => {
    if (!clinicId) return

    const res = await axios.post(
        `${SYNC_SERVER_URL}/connect/${clinicId}/cloud-full-sync`
    )

    return res.data
}

export const confirmAppointment = async (
    clinicId?: string,
    appointmentId?: string
) => {
    if (!clinicId || !appointmentId) return

    const res = await axios.post(
        `${
            process.env.GATSBY_REST_API_URL || 'https://dev.api.chckup.app'
        }/connect/${clinicId}/confirm-appointment/${appointmentId}`
    )

    return res.data
}

export const denyAppointment = async (
    clinicId?: string,
    appointmentId?: string,
    reasonForDenial?: string
) => {
    if (!clinicId || !appointmentId) return

    const res = await axios.post(
        `${
            process.env.GATSBY_REST_API_URL || 'https://dev.api.chckup.app'
        }/connect/${clinicId}/deny-appointment`,
        {
            ApptId: appointmentId,
            DenyReason: reasonForDenial || 'Clinician not available',
        }
    )

    return res.data
}
