import React from 'react'
import { Tag, TagLabel, TagProps } from '@chakra-ui/tag'

export default function DeceasedTag(props: TagProps) {
    return (
        <Tag size="sm" rounded="md" colorScheme="gray" {...props}>
            <TagLabel>Deceased</TagLabel>
        </Tag>
    )
}
