import React from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    ModalOverlayProps,
    ModalProps,
} from '@chakra-ui/modal'
import { ChevronLeftIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import { Button, IconButton, useBreakpointValue } from '@chakra-ui/react'

import {
    AppointmentFieldsFragment,
    RescheduleFieldsFragment,
} from '~graphql/generated/graphql'
import AppointmentDetails from '~components/appointments/detailed-appointment/details'
import { Link } from 'gatsby'
import { UserRoutes } from '~config/routes'

interface Props extends Omit<ModalProps, 'children'> {
    appointment: AppointmentFieldsFragment | undefined
    reschedule?: RescheduleFieldsFragment
    overlayOverride?: ModalOverlayProps
}

const AppointmentDetailsDrawer: React.FC<Props> = ({
    appointment,
    reschedule,
    isOpen,
    onClose,
    overlayOverride,
    ...props
}) => {
    const modalSize = useBreakpointValue({ base: 'full', md: 'lg' })

    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            blockScrollOnMount={false}
            size={modalSize}
            {...props}
        >
            <DrawerOverlay {...overlayOverride} />
            <DrawerContent>
                <DrawerHeader
                    pb={4}
                    shadow="blue.md"
                    display="flex"
                    justifyContent="space-between"
                >
                    <IconButton
                        size="sm"
                        icon={<ChevronLeftIcon fontSize="xl" />}
                        variant="outline"
                        rounded="full"
                        aria-label="Close Modal"
                        onClick={onClose}
                    />
                    <Link
                        to={UserRoutes.AppointmentDetails(
                            appointment?.id || ''
                        )}
                    >
                        <Button
                            size="sm"
                            rightIcon={<ExternalLinkIcon fontSize="lg" />}
                            variant="outline"
                            rounded="full"
                        >
                            View all details
                        </Button>
                    </Link>
                </DrawerHeader>
                <DrawerBody px={{ base: 2, md: 6 }} py={{ base: 4, md: 6 }}>
                    {appointment && (
                        <AppointmentDetails
                            appointment={appointment}
                            reschedule={reschedule}
                        />
                    )}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export default AppointmentDetailsDrawer
