import React, { PropsWithChildren } from 'react'
import { IconType } from 'react-icons'
import { Icon } from '@chakra-ui/icons'
import { Text, HStack } from '@chakra-ui/layout'
import { MenuItem as ChakraMenuItem } from '@chakra-ui/menu'

interface Props {
    onClick?: () => void
    icon?: IconType
}

export default function MenuItem({
    children,
    onClick,
    icon,
}: PropsWithChildren<Props>) {
    return (
        <ChakraMenuItem onClick={onClick}>
            <HStack spacing={2}>
                {icon && <Icon as={icon} />}
                <Text fontSize="sm" fontWeight="medium">
                    {children}
                </Text>
            </HStack>
        </ChakraMenuItem>
    )
}
