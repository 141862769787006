import React, { useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { auth } from '~config/firebase'
import {
    signOut,
    multiFactor,
    User as AuthUser,
    onAuthStateChanged,
} from 'firebase/auth'

import {
    AuthRoles,
    useGetUserQuery,
    UserFieldsFragment,
} from '~graphql/generated/graphql'
import { validateEmail } from '~utils/helpers'
import { LocalStorageKeys } from '~config/constants'
import { UserRoutes } from './routes'

export const UserContext = React.createContext<UserProviderType>({
    user: undefined,
    isLoading: true,
    userId: '',
    isAdmin: false,
    isEmailVerified: false,
    isPhoneVerified: false,
    hasMFAEnabled: false,
    signOut: () => {},
    refetchUser: async () => {},
})

type UserProviderType = {
    user: UserFieldsFragment | undefined
    isLoading: boolean
    userId: string
    isAdmin: boolean
    isEmailVerified: boolean
    isPhoneVerified: boolean
    hasMFAEnabled: boolean
    signOut: () => void
    refetchUser: () => Promise<void>
}

export const UserProvider = ({ children }: { children: React.ReactNode }) => (
    <UserContext.Provider value={{ ...useUser() }}>
        {children}
    </UserContext.Provider>
)

export type LoginStatus = 'error' | 'success' | 'requires-mfa'

function useUser(): UserProviderType {
    const [userId, setUserId] = useState('')
    const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false)
    const [isPhoneVerified, setIsPhoneVerified] = useState<boolean>(false)
    const [hasMFAEnabled, setHasMFAEnabled] = useState<boolean>(false)
    const [{ user, isLoading }, setUser] = useState<{
        user: UserFieldsFragment | undefined
        isLoading: boolean
    }>({
        user: undefined,
        isLoading: false,
    })
    const [isAdmin, setIsAdmin] = useState(
        (user?.role &&
            [AuthRoles.SuperAdmin, AuthRoles.Admin].includes(user.role)) ||
            false
    )

    function handleSignOut() {
        localStorage.removeItem(LocalStorageKeys.authToken)
        setUser({ user: undefined, isLoading: true })
        setUserId('')
        signOut(auth).then(() => {
            navigate(UserRoutes.Auth)
            setUser({ user: undefined, isLoading: false })
            setUserId('')
        })
    }

    useEffect(() => {
        if (user) {
            setIsAdmin(
                (user.role &&
                    [AuthRoles.SuperAdmin, AuthRoles.Admin].includes(
                        user.role
                    )) ||
                    false
            )
        }
    }, [user])

    const { refetch: refetchUser } = useGetUserQuery({
        onCompleted: data => {
            setUser({
                isLoading: false,
                user: data?.user || undefined,
            })
        },
        skip: true,
    })

    async function handleRefetch() {
        const { data } = await refetchUser()

        setUser({
            user: data.user || undefined,
            isLoading: false,
        })
    }

    useEffect(() => {
        const unsub = onAuthStateChanged(auth, async userAuth => {
            handleUserAuthRetrieved(userAuth)
        })

        return () => {
            unsub()
        }
    }, [])

    const handleUserAuthRetrieved = async (userAuth: AuthUser | null) => {
        if (userAuth) {
            const multiFactorUser = multiFactor(userAuth)
            setUserId(userAuth.uid)
            setIsEmailVerified(userAuth.emailVerified)
            setIsPhoneVerified(
                multiFactorUser.enrolledFactors?.at(0)?.displayName === 'phone'
                    ? true
                    : false
            )
            setHasMFAEnabled(
                multiFactorUser.enrolledFactors &&
                    multiFactorUser.enrolledFactors.length > 0
            )
            const token = await userAuth.getIdToken()
            localStorage.setItem(LocalStorageKeys.authToken, token)
            const { data } = await refetchUser({
                id: userAuth.uid,
            })

            setUser({
                user: data.user || undefined,
                isLoading: false,
            })
        } else {
            setUser({
                user: undefined,
                isLoading: false,
            })
        }
    }

    useEffect(() => {
        if (auth.currentUser && auth.currentUser!.uid) {
            setUserId(auth.currentUser!.uid)
        }
    }, [])

    return {
        user,
        isLoading,
        userId,
        isEmailVerified,
        isPhoneVerified,
        hasMFAEnabled,
        isAdmin,
        signOut: handleSignOut,
        refetchUser: handleRefetch,
    }
}

export const useUserContext = () => {
    return useContext(UserContext)
}
