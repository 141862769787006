import React from 'react'
import {
    Drawer,
    ModalProps,
    DrawerBody,
    DrawerOverlay,
    DrawerHeader,
    DrawerContent,
} from '@chakra-ui/modal'
import { IconButton } from '@chakra-ui/button'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { useBreakpointValue } from '@chakra-ui/media-query'

import DetailedClientInfo from './detailed-client-info'
import LoadingView from '~components/shared/loading-view'
import { ClientFieldsFragment } from '~graphql/generated/graphql'

interface Props extends Omit<ModalProps, 'children'> {
    client?: ClientFieldsFragment
    loading?: boolean
}

const ClientDetailsDrawer: React.FC<Props> = ({
    client,
    loading,
    ...props
}) => {
    const modalSize = useBreakpointValue({ base: 'full', md: 'lg' })

    return (
        <Drawer
            placement="right"
            size={modalSize}
            blockScrollOnMount={false}
            {...props}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader
                    pb={4}
                    shadow="blue.md"
                    display="flex"
                    justifyContent="space-between"
                >
                    <IconButton
                        size="sm"
                        icon={<ChevronLeftIcon fontSize="xl" />}
                        variant="outline"
                        rounded="full"
                        aria-label="Close Modal"
                        onClick={props.onClose}
                    />
                </DrawerHeader>
                <DrawerBody px={{ base: 2, md: 6 }} py={{ base: 4, md: 6 }}>
                    {loading ? (
                        <LoadingView />
                    ) : (
                        <DetailedClientInfo client={client} />
                    )}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export default ClientDetailsDrawer
