import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import fetch from 'isomorphic-fetch'
import { Font } from '@react-pdf/renderer'

import '@fontsource/nunito/200.css'
import '@fontsource/nunito/300.css'
import '@fontsource/nunito/400.css'
import '@fontsource/nunito/500.css'
import '@fontsource/nunito/600.css'
import '@fontsource/nunito/700.css'
import '@fontsource/nunito/800.css'
import '@fontsource/nunito/900.css'
import '@fontsource/inter/100.css'
import '@fontsource/inter/200.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/800.css'
import '@fontsource/inter/900.css'

import Inter400 from '@fontsource/inter/files/inter-all-400-normal.woff'
import Inter500 from '@fontsource/inter/files/inter-all-500-normal.woff'
import Inter600 from '@fontsource/inter/files/inter-all-600-normal.woff'
import Inter700 from '@fontsource/inter/files/inter-all-700-normal.woff'
import Inter800 from '@fontsource/inter/files/inter-all-800-normal.woff'
import Inter900 from '@fontsource/inter/files/inter-all-900-normal.woff'

import theme from '../@chakra-ui/gatsby-plugin/theme'
import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
    createHttpLink,
} from '@apollo/client'

import { authLink } from '~utils/auth-helpers'
import { UserProvider } from '~config/user-context'
import { ClinicProvider } from '~contexts/clinic-context'
import { PendingAppointmentsProvider } from '~contexts/pending-appointments-context'
import { LoadingProvider } from '~config/loading-context'
import SyncProvider from '~contexts/sync-context'
import { ActionItemProvider } from '~contexts/action-item-provider'
import { ReportTemplatesProvider } from '~contexts/report-templates-provider'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { InactivityProvider } from '~config/inactivity-timeout-context'

interface Props {
    element: React.ReactNode
}

const WrapRootElement: React.FC<Props> = ({ element }) => {
    const link = createHttpLink({
        uri: process.env.GATSBY_API_URL || 'https://dev.api.chckup.app/graphql',
        fetch,
    })

    const client = new ApolloClient({
        link: authLink.concat(link),
        cache: new InMemoryCache(),
    })

    Font?.register({
        family: 'Inter',
        fonts: [
            {
                src: Inter400,
                fontWeight: 400,
            },
            {
                src: Inter500,
                fontWeight: 500,
            },
            {
                src: Inter600,
                fontWeight: 600,
            },
            {
                src: Inter700,
                fontWeight: 700,
            },
            {
                src: Inter800,
                fontWeight: 800,
            },
            {
                src: Inter900,
                fontWeight: 900,
            },
        ],
    })

    return (
        <>
            <script
                src={`https://maps.googleapis.com/maps/api/js?key=${
                    process.env.GATSBY_GCP_KEY as string
                }&libraries=places`}
            ></script>
            <DndProvider backend={HTML5Backend}>
                <ApolloProvider client={client}>
                    <ChakraProvider theme={theme}>
                        <LoadingProvider>
                            <UserProvider>
                                <ClinicProvider>
                                    <InactivityProvider>
                                        <SyncProvider>
                                            <ReportTemplatesProvider>
                                                <PendingAppointmentsProvider>
                                                    <ActionItemProvider>
                                                        {element}
                                                    </ActionItemProvider>
                                                </PendingAppointmentsProvider>
                                            </ReportTemplatesProvider>
                                        </SyncProvider>
                                    </InactivityProvider>
                                </ClinicProvider>
                            </UserProvider>
                        </LoadingProvider>
                    </ChakraProvider>
                </ApolloProvider>
            </DndProvider>
        </>
    )
}

export default WrapRootElement
