import React, { useState } from 'react'
import { Box, Text, Flex } from '@chakra-ui/layout'

import ClientPatientItem from './patient-item'
import DetailedClientHeader from './client-header'
import EmptyState from '~components/shared/empty-state'
import SwitchButton from '~components/shared/switch-button'
import GradientDivider from '~components/shared/gradient-divider'
import { ClientFieldsFragment } from '~graphql/generated/graphql'
import SharedStack from '~components/shared/wrappers/shared-stack'
import { sortPatientsByName } from '~utils/helpers'

interface Props {
    client?: ClientFieldsFragment
}

export default function DetailedClientInfo({ client }: Props) {
    const [showAllPets, setShowAllPets] = useState<boolean>(false)

    if (!client) return <EmptyState title="Client Not Found" />

    const patients = [...(client.patients || [])]
        ?.sort(sortPatientsByName)
        .filter(p => (showAllPets ? true : !p.is_deceased))

    return (
        <>
            <Box px={2}>
                <DetailedClientHeader client={client} />
            </Box>
            <GradientDivider my={4} />
            <Flex justifyContent="space-between" alignItems="center" mb={3}>
                <Text fontWeight="semibold">Patients</Text>
                {patients.length > 0 && (
                    <SwitchButton
                        isChecked={showAllPets}
                        onClick={() => setShowAllPets(!showAllPets)}
                    >
                        Show deceased?
                    </SwitchButton>
                )}
            </Flex>
            <SharedStack>
                {patients.length === 0 ? (
                    <EmptyState title="No Patients" subtitle=" " py={16} />
                ) : (
                    patients.map(patient => (
                        <ClientPatientItem key={patient.id} patient={patient} />
                    ))
                )}
            </SharedStack>
        </>
    )
}
