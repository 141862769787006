import { mode } from '@chakra-ui/theme-tools'
import { defineStyleConfig } from '@chakra-ui/react'

export const Input = defineStyleConfig({
    baseStyle: {
        borderWidth: 2,
    },
    defaultProps: {
        // @ts-ignore - This definitely works but shows an error
        focusBorderColor: 'brand.500',
    },
    variants: {
        filled: props => ({
            field: {
                borderWidth: 0,
                _focusVisible: {
                    bg: mode('gray.100', 'whiteAlpha.50')(props),
                },
            },
        }),
    },
})
