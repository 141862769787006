import React from 'react'
import { navigate } from 'gatsby'
import { Avatar } from '@chakra-ui/avatar'
import { Button } from '@chakra-ui/button'
import { Box, Text, HStack, Link } from '@chakra-ui/layout'
import { Menu, MenuList, MenuButton, MenuGroup } from '@chakra-ui/menu'

import { OrganizationFieldsFragment } from '~graphql/generated/graphql'
import { UserRoutes } from '~config/routes'
import MenuItem from '~components/shared/menu/menu-item'
import { useUserContext } from '~config/user-context'
import { getSupabaseLink, SupabaseEntities } from '~config/constants'
import { BiCopy, BiInfoCircle, BiLinkExternal, BiRefresh } from 'react-icons/bi'

interface Props {
    organization: OrganizationFieldsFragment
    setOrganization: (e: OrganizationFieldsFragment | undefined) => void
    canSwitch?: boolean
}

const OrganizationHeader: React.FC<Props> = ({
    organization,
    setOrganization,
    canSwitch = true,
}) => {
    const { isAdmin } = useUserContext()

    return (
        <HStack>
            <Menu placement="bottom-end">
                <MenuButton
                    as={Button}
                    variant="subtle"
                    size="xs"
                    px={3}
                    colorScheme="brand"
                    textTransform={'uppercase'}
                >
                    {organization.name}
                </MenuButton>
                <MenuList pt={0}>
                    <Box px={4} py={2}>
                        <HStack spacing={4}>
                            <Avatar
                                size="sm"
                                src={''}
                                name={organization.name || ''}
                            />
                            <Box>
                                <Text fontSize="sm" fontWeight="semibold">
                                    {organization.name}
                                </Text>
                                {isAdmin && (
                                    <Text fontSize="xs" color="gray.500">
                                        {organization.id}
                                    </Text>
                                )}
                            </Box>
                        </HStack>
                    </Box>
                    <MenuGroup title="Options" fontSize="xs">
                        <MenuItem
                            icon={BiInfoCircle}
                            onClick={() => navigate('/enterprise/info')}
                        >
                            View Info
                        </MenuItem>
                        {(canSwitch || isAdmin) && (
                            <MenuItem
                                icon={BiRefresh}
                                onClick={() => {
                                    setOrganization(undefined)
                                    navigate(UserRoutes.SelectClinic)
                                }}
                            >
                                Switch Organization
                            </MenuItem>
                        )}
                    </MenuGroup>
                    {isAdmin && (
                        <MenuGroup title="Admin" fontSize="xs">
                            <MenuItem
                                icon={BiCopy}
                                onClick={() =>
                                    navigator.clipboard.writeText(
                                        organization.id
                                    )
                                }
                            >
                                Copy Clinic ID
                            </MenuItem>
                            <Link
                                variant="unstyled"
                                href={getSupabaseLink(
                                    SupabaseEntities.Organization,
                                    organization.id
                                )}
                                isExternal
                            >
                                <MenuItem icon={BiLinkExternal}>
                                    View in Supabase
                                </MenuItem>
                            </Link>
                        </MenuGroup>
                    )}
                </MenuList>
            </Menu>
        </HStack>
    )
}

export default OrganizationHeader
