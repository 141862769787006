import React from 'react'
import { Link } from 'gatsby'
import { Icon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/button'
import { Box, Flex } from '@chakra-ui/layout'
import { FiExternalLink } from 'react-icons/fi'

import { UserRoutes } from '~config/routes'
import SharedLabel from '~components/shared/form/shared-label'
import SharedHelpText from '~components/shared/form/shared-help-text'

export default function CustomizationLink() {
    return (
        <Flex w="full" justifyContent="space-between" alignItems="center">
            <Box>
                <SharedLabel fontSize="md">Customizations</SharedLabel>
                <SharedHelpText fontSize="sm">
                    You can customize the look and terminology used on your
                    booking forms on the customizations page.
                </SharedHelpText>
            </Box>
            <Link to={UserRoutes.Customization}>
                <Button
                    colorScheme="brand"
                    rightIcon={<Icon as={FiExternalLink} />}
                >
                    Go to customizations
                </Button>
            </Link>
        </Flex>
    )
}
