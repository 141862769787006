import React from 'react'
import { Box, Text, Flex, HStack, SimpleGrid } from '@chakra-ui/layout'

import { SupabaseEntities } from '~config/constants'
import AdminIdButton from '~components/shared/buttons/admin-id-button'
import SpeciesIcon from '~components/shared/patient/species-icon'
import {
    AnimalGender,
    FixedStatus,
    PatientFieldsFragment,
    Species,
} from '~graphql/generated/graphql'
import InfoItem from '~components/shared/info-item'
import { format, formatDistanceToNow } from 'date-fns'

interface Props {
    patient: PatientFieldsFragment
}

export default function DetailedPatientHeader({ patient }: Props) {
    const species = patient?.clinic_species?.species || Species.Other

    return (
        <>
            <Flex mb={4} justifyContent="space-between" alignItems="center">
                <HStack spacing={4}>
                    <SpeciesIcon patient={patient} />
                    <Box>
                        <Text fontWeight="bold" fontSize="xl">
                            {patient.name}
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                            {patient.owner?.first_name +
                                ' ' +
                                patient.owner?.last_name}
                        </Text>
                    </Box>
                </HStack>
                <AdminIdButton
                    entity={SupabaseEntities.Patient}
                    id={patient.id}
                />
            </Flex>
            <SimpleGrid columns={2} spacing={4}>
                <InfoItem
                    label="Species"
                    info={<Text fontSize="sm">{species}</Text>}
                    isShorter
                />
                <InfoItem
                    label="Breed"
                    info={
                        <Text fontSize="sm">
                            {patient?.clinic_breed?.description ||
                                patient?.breed}
                        </Text>
                    }
                    isShorter
                />
                <InfoItem
                    label="Gender"
                    info={
                        <Text
                            fontSize="sm"
                            fontStyle={
                                patient?.gender !== AnimalGender.Unknown
                                    ? 'normal'
                                    : 'italic'
                            }
                        >
                            {patient?.gender || 'Unknown'}
                        </Text>
                    }
                    isShorter
                />
                <InfoItem
                    label="Fixed Status"
                    info={
                        <Text
                            fontSize="sm"
                            fontStyle={
                                patient?.fixed_status !== FixedStatus.Unknown
                                    ? 'normal'
                                    : 'italic'
                            }
                        >
                            {patient?.fixed_status || 'Unknown'}
                        </Text>
                    }
                    isShorter
                />
                <InfoItem
                    label="Birthdate"
                    info={
                        <Text fontSize="sm">
                            {patient?.birthday
                                ? format(
                                      new Date(patient?.birthday),
                                      'MMM dd, y'
                                  )
                                : '-'}
                        </Text>
                    }
                    isShorter
                />
                <InfoItem
                    label="Age"
                    info={
                        <Text fontSize="sm">
                            {patient?.birthday
                                ? formatDistanceToNow(
                                      new Date(patient?.birthday),
                                      {
                                          addSuffix: false,
                                      }
                                  )
                                : '-'}
                        </Text>
                    }
                    isShorter
                />
            </SimpleGrid>
        </>
    )
}
