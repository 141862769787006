import React from 'react'
import { TabPanel } from '@chakra-ui/tabs'
import { VStack } from '@chakra-ui/layout'

import GradientDivider from '~components/shared/gradient-divider'
import { ClinicFieldsFragment } from '~graphql/generated/graphql'
import SyncSettingsProvider from './sync-settings-context'
import { useSyncSettingsContext } from '../sync-settings/sync-settings-context'
import SaveActionsBtn from '../save-changes-btn'
import ShouldSyncRemindersSwitch from './should-sync-reminders-switch'
import SyncIntervalsInputs from './sync-intervals-inputs'

export default function SyncSettingsPanel({
    clinic,
}: {
    clinic: ClinicFieldsFragment
}) {
    return (
        <TabPanel pb={0}>
            <SyncSettingsProvider clinic={clinic}>
                <VStack
                    w="full"
                    align="flex-start"
                    spacing={8}
                    pb={8}
                    pt={4}
                    divider={<GradientDivider />}
                    minH="100vh"
                >
                    <SyncIntervalsInputs />
                    <ShouldSyncRemindersSwitch />
                </VStack>
                <SyncSaveActions />
            </SyncSettingsProvider>
        </TabPanel>
    )
}

function SyncSaveActions() {
    const { canSave, isUpdateLoading, handleUpdateSettings } =
        useSyncSettingsContext()

    return (
        <SaveActionsBtn
            canSave={canSave}
            isUpdateLoading={isUpdateLoading}
            handleUpdateSettings={handleUpdateSettings}
        />
    )
}
