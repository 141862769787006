import React from 'react'
import SharedHelpText from '~components/shared/form/shared-help-text'
import SharedInput from '~components/shared/form/shared-input'
import DetailText from '~components/shared/texts/detail-text'
import SharedInfoText from '~components/shared/texts/shared-info-text'
import SharedStack from '~components/shared/wrappers/shared-stack'
import SettingInput from '../form/setting-input'
import { useBookingSettingsContext } from './booking-settings-context'

export default function CustomNotesInputs() {
    const {
        customNotesTemplate,
        customNewClientNotesTemplate,
        customNewPatientNotesTemplate,
        setCustomNotesTemplate,
        setCustomNewClientNotesTemplate,
        setCustomNewPatientNotesTemplate,
    } = useBookingSettingsContext()

    return (
        <SharedStack spacing={4} fontSize="md">
            <SettingInput
                label="Custom notes for booked appointments"
                value={customNotesTemplate}
                onChange={e => setCustomNotesTemplate(e.target.value)}
                placeholder={'{{ client_notes }}'}
            />
            <SettingInput
                label="Custom notes for new patient appointments"
                value={customNewPatientNotesTemplate}
                onChange={e => setCustomNewPatientNotesTemplate(e.target.value)}
                placeholder={'{{ client_notes }}'}
                moreInfo={
                    <SharedHelpText fontSize="sm" mb={0}>
                        This will take precedent over the custom notes for all
                        new patient appointments.
                    </SharedHelpText>
                }
            />
            <SettingInput
                label="Custom notes for new client appointments"
                value={customNewClientNotesTemplate}
                onChange={e => setCustomNewClientNotesTemplate(e.target.value)}
                placeholder={'{{ client_notes }}'}
                moreInfo={
                    <SharedHelpText fontSize="sm" mb={0}>
                        This will take precedent over both other custom notes
                        for all new client appointments.
                    </SharedHelpText>
                }
            />
            <SharedStack spacing={1}>
                <DetailText color="gray.800" fontWeight="semibold">
                    Available Customization Options
                </DetailText>
                <DetailText>{`{{ client_notes }}, {{ client_first_name }}, {{ client_last_name }}, {{ client_phone }}, {{ client_email }}, {{ client_address }}, {{ patient_name }}, {{ patient_species }}, {{ patient_breed }}, {{ appointment_service }}, {{ clinician_name }}, {{ previous_clinic }}`}</DetailText>
            </SharedStack>
        </SharedStack>
    )
}
