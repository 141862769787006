import React, { useMemo } from 'react'
import { Text } from '@chakra-ui/layout'
import { useLocation } from '@reach/router'

const HeaderTitle: React.FC = () => {
    const { pathname } = useLocation()

    const paths = [
        {
            label: 'Daily Huddle',
            to: '/daily-huddle',
        },
        {
            label: 'Pending Actions',
            to: '/pending-actions',
        },
        {
            label: 'Messages',
            to: '/chats',
        },
        {
            label: 'Schedule',
            to: '/schedule',
        },
        {
            label: 'All Appointments',
            to: '/appointments',
        },
        {
            label: 'Clinicians',
            to: '/manage/clinicians',
        },
        {
            label: 'Payments',
            to: '/payments/all',
        },
        {
            label: 'Scheduling Rules',
            to: '/manage/scheduling-rules',
        },
        {
            label: 'Services',
            to: '/manage/services',
        },
        {
            label: 'Species',
            to: '/manage/species',
        },
        {
            label: 'Widgets / URLs',
            to: '/manage/widgets',
        },
        {
            label: 'All Analytics',
            to: '/analytics',
        },
        {
            label: 'Goals',
            to: '/analytics/goals',
        },
        {
            label: 'Admins',
            to: '/manage/admins',
        },
        {
            label: 'Clinic Info',
            to: '/manage/info',
        },
        // {
        //     label: 'ChckupConnect',
        //     to: '/manage/connect',
        // },
        {
            label: 'Notification Center',
            to: '/manage/notification-center',
        },
        {
            label: 'Settings',
            to: '/settings',
        },
        {
            label: 'Clinics',
            to: '/admin/clinic',
        },
        {
            label: 'Widgets',
            to: '/admin/widgets',
        },
        {
            label: 'Users',
            to: '/admin/users',
        },
    ]

    const title = useMemo(() => {
        const path = paths.find(path => pathname.includes(path.to))
        return path ? path.label : 'Home'
    }, [pathname])

    return (
        <Text fontWeight="extrabold" fontSize="lg">
            {title}
        </Text>
    )
}

export default HeaderTitle
