import React from 'react'
import { Text, TextProps } from '@chakra-ui/react'

interface SharedHelpTextProps extends TextProps {}

export default function SharedHelpText({
    children,
    ...rest
}: SharedHelpTextProps) {
    return (
        <Text color="gray.500" fontSize="12px" mb={2} {...rest}>
            {children}
        </Text>
    )
}
