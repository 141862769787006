import React from 'react'
import { format, addMinutes, formatDistanceToNowStrict } from 'date-fns'
import { ChevronRightIcon, Icon } from '@chakra-ui/icons'
import { FiClock } from 'react-icons/fi'
import { Avatar } from '@chakra-ui/avatar'
import { Tooltip } from '@chakra-ui/tooltip'
import { Box, Text, Flex, HStack } from '@chakra-ui/layout'

import {
    FilterOp,
    PatientFieldsFragment,
    AppointmentFieldsFragment,
    useQueryClinicAppointmentsQuery,
} from '~graphql/generated/graphql'
import { useClinic } from '~contexts/clinic-context'
import OutlineBox from '~components/shared/wrappers/outline-box'
import { navigate } from 'gatsby'
import Clickable from '~components/shared/form/clickable'
import AppointmentStatusTag from '~components/shared/appointment-status-tag'
import EmptyState from '~components/shared/empty-state'
import LoadingView from '~components/shared/loading-view'
import { getAppointmentStatusFromAppointment } from '~utils/appointment-helpers'
import { constructQueryString } from '~utils/construct-query-string'
import ClinicianAvatar from '~components/shared/clinician-avatar'

interface Props {
    patient: PatientFieldsFragment
}

export default function PatientAppointments({ patient }: Props) {
    const { clinic: globalClinic } = useClinic()

    const clinicId = patient?.clinic?.id || globalClinic?.id

    const { data, loading } = useQueryClinicAppointmentsQuery({
        variables: {
            clinicId: clinicId || '',
            where: [
                {
                    column: 'patient',
                    filterOp: FilterOp.Eq,
                    value: {
                        string: patient.id,
                    },
                },
                {
                    column: 'is_deleted',
                    filterOp: FilterOp.Is,
                    value: {
                        boolean: false,
                    },
                },
            ],
            page: 0,
            pageSize: 10,
            orderBy: {
                column: 'start_time',
                isAscending: false,
            },
        },
        skip: !clinicId,
    })

    const appointments = data?.queryClinicAppointments || []

    if (loading) {
        return <LoadingView py={16} />
    }

    if (appointments.length === 0) {
        return <EmptyState title="No Appointments" subtitle=" " py={16} />
    }

    return (
        <>
            {appointments.map(appointment => (
                <AppointmentItem
                    key={appointment.id}
                    appointment={appointment}
                />
            ))}
        </>
    )
}

interface ApptProps {
    appointment: AppointmentFieldsFragment
}

function AppointmentItem({ appointment }: ApptProps) {
    const { start_time, minutes, service, service_name, clinician } =
        appointment

    const status = getAppointmentStatusFromAppointment(appointment)

    function handleClick() {
        const queryString = constructQueryString({
            newAppointment: appointment.id,
        })

        navigate(queryString)
    }

    return (
        <Clickable w="full" rounded="xl" onClick={handleClick}>
            <OutlineBox w="full" py={3} bg="transparent" shadow="none">
                <Flex alignItems="center" gap={4}>
                    <Flex
                        flex={1}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <HStack spacing={6}>
                            <HStack spacing={3}>
                                <AppointmentStatusTag status={status} />
                                <Box>
                                    <Text fontSize="sm" fontWeight="semibold">
                                        {service?.name || service_name}{' '}
                                        Appointment
                                    </Text>
                                    <Text fontSize="sm" color="gray.500">
                                        {formatDistanceToNowStrict(start_time, {
                                            addSuffix: true,
                                        })}
                                    </Text>
                                </Box>
                            </HStack>
                            <HStack spacing={3}>
                                <Icon
                                    as={FiClock}
                                    fontSize="lg"
                                    color="gray.600"
                                />
                                <Box>
                                    <Text
                                        fontSize="sm"
                                        lineHeight="short"
                                        fontWeight="medium"
                                    >
                                        {format(start_time, 'p')} -{' '}
                                        {format(
                                            addMinutes(start_time, minutes),
                                            'p'
                                        )}
                                    </Text>
                                    <Text
                                        fontSize="sm"
                                        lineHeight="short"
                                        color="gray.500"
                                    >
                                        {format(start_time, 'PP')}
                                    </Text>
                                </Box>
                            </HStack>
                        </HStack>
                        <Tooltip label={clinician?.name} hasArrow>
                            <ClinicianAvatar clinician={clinician} size="sm" />
                        </Tooltip>
                    </Flex>
                    <ChevronRightIcon fontSize="xl" color="gray.500" />
                </Flex>
            </OutlineBox>
        </Clickable>
    )
}
